import { useRef } from "react"
import { deepEqual } from "@firebase/util"

const useMemoDeepCompare = <T extends object>(value: T) => {
  const ref = useRef<T>(value)

  const b = deepEqual(value, ref.current)
  // console.info("useMemoDeepCompare", { b, value, ref: ref.current })

  if (!b) {
    ref.current = value
  }

  return ref.current
}

export default useMemoDeepCompare
