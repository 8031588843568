"use client"

import { Auth, connectAuthEmulator, getAuth } from "firebase/auth"
import {
  connectFunctionsEmulator,
  Functions,
  getFunctions,
} from "firebase/functions"
import { FirebaseApp, initializeApp } from "firebase/app"
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore,
} from "firebase/firestore"
import { firebaseConfig } from "./clientApp"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import useMemoDeepCompare from "@/support/react/useMemoDeepCompare"
import { useClog } from "@/util/clog.client"

export type FirebaseContextValue = {
  app: FirebaseApp
  auth: Auth
  functions: Functions
  firestore: Firestore
  debugOptions: NewieDebugOptions
  setDebugOptions: (
    update: (prev: NewieDebugOptions) => NewieDebugOptions,
  ) => void
  resetDebugOptions: () => void
}

export type NewieDebugOptions = {
  failFirebaseFunctions: boolean
}

export const newDebugOptionDefaults: NewieDebugOptions = {
  failFirebaseFunctions: false,
} as const

export const FirebaseContext = createContext<FirebaseContextValue | undefined>(
  undefined,
)

export const FirebaseProvider = ({ children }: { children: ReactNode }) => {
  const app = useMemo(() => initializeApp(firebaseConfig()), [])
  const clog = useClog("FirebaseProvider")

  // -- auth -- setup and connect to emulator if it's enabled
  const authEmulatorAddress = process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR
  const auth = useMemo(() => {
    const auth = getAuth(app)
    if (authEmulatorAddress) {
      clog("Connecting to auth emulator", authEmulatorAddress)
      connectAuthEmulator(auth, authEmulatorAddress)
    }
    return auth
  }, [app, clog, authEmulatorAddress])

  // -- functions -- setup and connect to emulator if it's enabled
  const functionsEmulatorAddress =
    process.env.NEXT_PUBLIC_FIREBASE_FUNCTIONS_EMULATOR_HOST
  const functions = useMemo(() => {
    const functions = getFunctions(app)
    if (functionsEmulatorAddress) {
      clog("Connecting to functions emulator", functionsEmulatorAddress)
      const [host, port] = functionsEmulatorAddress.split(":")
      connectFunctionsEmulator(functions, host, parseInt(port))
    }
    return functions
  }, [app, clog, functionsEmulatorAddress])

  // -- firestore -- setup and connect to emulator if it's enabled
  const firestoreEmulatorAddress =
    process.env.NEXT_PUBLIC_FIREBASE_FIRESTORE_EMULATOR
  const firestore = useMemo(() => {
    const firestore = getFirestore(app)
    if (firestoreEmulatorAddress) {
      clog("Connecting to firestore emulator", firestoreEmulatorAddress)
      const [hostname, port] = firestoreEmulatorAddress.split(":")
      connectFirestoreEmulator(firestore, hostname, parseInt(port))
    }
    return firestore
  }, [app, clog, firestoreEmulatorAddress])

  // -- debug options
  const [debugOptions, setDebugOptions] = useState<NewieDebugOptions>(
    newDebugOptionDefaults,
  )

  const memoDebugOptions = useMemoDeepCompare(debugOptions)

  const resetDebugOptions = useCallback(() => {
    setDebugOptions(newDebugOptionDefaults)
  }, [setDebugOptions])

  const value = useMemo(
    () => ({
      app,
      auth,
      functions,
      firestore,
      debugOptions: memoDebugOptions,
      setDebugOptions,
      resetDebugOptions,
    }),
    [
      app,
      auth,
      functions,
      firestore,
      memoDebugOptions,
      setDebugOptions,
      resetDebugOptions,
    ],
  )

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  )
}

// Returns the initialized firebase services
// Handles using the emulators if env vars exist
export const useFirebase = (): FirebaseContextValue => {
  const context = useContext(FirebaseContext)
  if (!context) {
    throw new Error("useFirebase must be used within a FirebaseProvider")
  }
  return context
}
