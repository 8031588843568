"use client"

import { ReviewAgreement } from "@/models/reviewAgreement"
import {
  Functions,
  httpsCallable,
  HttpsCallableResult,
} from "firebase/functions"

import clog from "@/util/clog"
import { notifyBugsnag } from "@/support/bugsnag/bugsnags"
import { NewieDebugOptions } from "@/firebase/FirebaseProvider"

export function firebaseConfig() {
  return {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  }
}

/**
 * Creates an asynchronous function that calls a specified cloud function by name.
 * Handles notifying BugSnag if an error occurs, then rethrows the error.
 */
export const makeFunctionCall = <T, R>(
  functionName: string,
  options?: { throwOnFailureDisabled?: boolean },
) => {
  return async (
    {
      functions,
      debugOptions: { failFirebaseFunctions },
    }: { functions: Functions; debugOptions: NewieDebugOptions },
    params: T,
  ): Promise<
    typeof options extends { throwOnFailureDisabled: true }
      ?
          | (R & { success: true })
          | { success: false; code?: string; message?: string }
      : R & { success: true }
  > => {
    try {
      if (failFirebaseFunctions) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(
          `Debug Options: Simulating Firebase Function Failure for function ${functionName}`,
        )
      }

      const result = await httpsCallable<
        T,
        | (R & { success: true })
        | { success: false; code?: string; message?: string }
      >(functions, functionName).call(null, params)

      const data = result.data
      clog("Function call result", { functionName, params, data })

      if (!data.success && !options?.throwOnFailureDisabled) {
        const message =
          data.message ?? `Unsuccessful response calling ${functionName}`

        // noinspection ExceptionCaughtLocallyJS
        throw new Error(message, {
          cause: {
            message: "Unknown Error",
            ...data,
          },
        })
      }

      // When throwOnFailureDisabled is true, we can return either success or failure
      return data as typeof options extends { throwOnFailureDisabled: true }
        ?
            | (R & { success: true })
            | { success: false; code?: string; message?: string }
        : R & { success: true }
    } catch (e) {
      clog("Error calling function", { functionName, params, e })
      notifyBugsnag(e)
      throw e
    }
  }
}

export type PurchasePaymentDetails = {
  amount: number
  currency: string
  setupFee?: {
    amount: number
  }
  paymentIntent?: {
    id: string
    secret: string
  }
  setupIntent?: {
    id: string
    secret: string
  }
  ephemeralKey: string
  publishableKey: string
  customer: string
  customerSessionClientSecret?: string
}

export type PurchaseResponse = {
  success: boolean
  offeringID: string
  priceID: string
  paymentDetails: PurchasePaymentDetails
  paymentType: string
}

export type SubscriptionSubscribeResponse = PurchaseResponse & {
  subscriptionID?: string
  purchaseID?: string
}

export type CreateAgreementResponse = {
  success: boolean
  agreementID: string
  offeringID: string
  priceID: string
}

export const subscriptions_subscribe = makeFunctionCall<
  {
    offeringID: string
    priceID: string
    agreementID?: string
    override?: string
    createCustomerSession?: boolean
  },
  SubscriptionSubscribeResponse
>("subscriptions_subscribe")

export const offering_createAgreement = makeFunctionCall<
  {
    offeringID: string
    priceID: string
  },
  CreateAgreementResponse
>("offering_createAgreement")

export const purchase = makeFunctionCall<
  {
    offeringID: string
    priceID: string
    override?: string
    createCustomerSession?: boolean
  },
  PurchaseResponse
>("purchase")

export async function users_whoami(functions: Functions): Promise<unknown> {
  return await httpsCallable(functions, "users_whoami").call(null)
}

export type ImpersonateResponseData = { data: { token: string } }

export async function admin_impersonate(
  functions: Functions,
  params: { userID: string },
): Promise<ImpersonateResponseData> {
  return (await httpsCallable(functions, "admin_impersonate").call(
    null,
    params,
  )) as ImpersonateResponseData
}

export const payments_changeDefaultPaymentMethod = makeFunctionCall<
  {
    paymentMethodID: string
    currency: string
  },
  void
>("payments_changeDefaultPaymentMethod")

export type GenerateSetupIntentResponse = {
  publishableKey: string
  ephemeralKey: string
  setupIntent: string
  customer: string
}

export const payments_generateSetupIntent = makeFunctionCall<
  { currency: string },
  GenerateSetupIntentResponse
>("payments_generateSetupIntent")

export async function users_requestEmailChange(
  functions: Functions,
  params: any,
): Promise<HttpsCallableResult> {
  return await httpsCallable(functions, "users_requestEmailChange").call(
    null,
    params,
  )
}

export type users_previewEmailChangeParams = {
  token: string
}

export async function users_previewEmailChange(
  functions: Functions,
  params: users_previewEmailChangeParams,
): Promise<HttpsCallableResult> {
  return await httpsCallable(functions, "users_previewEmailChange").call(
    null,
    params,
  )
}

export type users_confirmEmailChangeParams = {
  token: string
}

export async function users_confirmEmailChange(
  functions: Functions,
  params: users_confirmEmailChangeParams,
): Promise<HttpsCallableResult> {
  return await httpsCallable(functions, "users_confirmEmailChange").call(
    null,
    params,
  )
}

export type users_rejectEmailChangeParams = {
  token: string
}

export async function users_rejectEmailChange(
  functions: Functions,
  params: users_rejectEmailChangeParams,
): Promise<HttpsCallableResult> {
  return await httpsCallable(functions, "users_rejectEmailChange").call(
    null,
    params,
  )
}

export type payments_retryParams = {
  stripeInvoiceID: string
}

export type PaymentsRetryResponse = {
  data: {
    charged: boolean
    failureReason?: string
  }
  success?: boolean
  message?: string
}

export async function payments_retry(
  functions: Functions,
  params: payments_retryParams,
): Promise<PaymentsRetryResponse> {
  return (await httpsCallable(functions, "payments_retry").call(
    null,
    params,
  )) as PaymentsRetryResponse
}

export type ListPaymentMethodsResponsePaymentMethod = {
  id: string
  default: boolean
  type: string
  display: {
    icon: string
    title: string
    subtitle: string
  }
  card?: {
    brand: string
    last4: string
    wallet?: {
      type: string
    }
  } | null
  au_becs_debit?: {
    bsb_number: string
    last4: string
    fingerprint: string
  } | null
}

export interface PaymentMethodForCurrency {
  currency: string
  stripeCustomerID: string
  paymentMethods: ListPaymentMethodsResponsePaymentMethod[]
}

export type ListPaymentMethodsResponse = {
  currencies: [PaymentMethodForCurrency]
}

export const payments_listPaymentMethods = makeFunctionCall<
  void,
  ListPaymentMethodsResponse
>("payments_listPaymentMethods")

export type AuthRequestLoginEmailResponse = {
  nonce: string
  success: boolean
  message?: string
  code?: string
}

export type AuthRequestLoginEmailParams = {
  email: string
}

export const auth_requestLoginEmail = makeFunctionCall<
  AuthRequestLoginEmailParams,
  AuthRequestLoginEmailResponse
>("auth_requestLoginEmail", {
  throwOnFailureDisabled: true,
})

export type AuthLoginWithEmailResponse = {
  email: string
  token: string
  success: boolean
  message?: string
  code?: string
}

export type AuthLoginWithEmailParams = {
  email: string
  nonce: string
  code: string
}

export const auth_loginWithEmail = makeFunctionCall<
  AuthLoginWithEmailParams,
  AuthLoginWithEmailResponse
>("auth_loginWithEmail")

export const users_syncProfile = makeFunctionCall<void, void>(
  "users_syncProfile",
)

export const agreements_review = makeFunctionCall<
  { agreementID: string },
  ReviewAgreement
>("agreements_review")

export type PaymentsFetchReceiptURLResponse =
  | {
      receiptURL: string
      success: true
    }
  | {
      success: false
      code: string
      message: string
    }

export async function payments_fetchReceiptURL(
  functions: Functions,
  params: {
    stripeInvoiceID: string
  },
): Promise<PaymentsFetchReceiptURLResponse> {
  return (
    await httpsCallable(functions, "payments_fetchReceiptURL").call(
      null,
      params,
    )
  ).data as PaymentsFetchReceiptURLResponse
}

export type PaymentsSendReceiptEmailResponse =
  | {
      notified: boolean
      success: true
    }
  | {
      success: false
      code: string
      message: string
    }

export async function payments_sendReceiptEmail(
  functions: Functions,
  params: {
    stripeInvoiceID: string
  },
): Promise<PaymentsSendReceiptEmailResponse> {
  return (
    await httpsCallable(functions, "payments_sendReceiptEmail").call(
      null,
      params,
    )
  ).data as PaymentsSendReceiptEmailResponse
}
