import { useMemo } from "react"

export const useClog = (tag: string): ((...args: unknown[]) => void) => {
  return useMemo(() => {
    return (...args: unknown[]) => {
      if (process.env.NODE_ENV === "development") {
        console.log(`[${tag}]`, ...args)
      }
    }
  }, [tag])
}
