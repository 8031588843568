import(/* webpackMode: "eager" */ "/home/runner/work/newie-web/newie-web/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/newie-web/newie-web/src/app/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/newie-web/newie-web/src/components/AppleIDClientScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/newie-web/newie-web/src/components/Monitoring.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalContextProvider"] */ "/home/runner/work/newie-web/newie-web/src/context/ModalContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/newie-web/newie-web/src/context/RouteHistoryContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/newie-web/newie-web/src/context/UserContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FirebaseProvider"] */ "/home/runner/work/newie-web/newie-web/src/firebase/FirebaseProvider.tsx");
