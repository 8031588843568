import Bugsnag from "@bugsnag/js"
import clog from "@/util/clog"

export const notifyBugsnag = (error: unknown) => {
  const typedError =
    error instanceof Error
      ? error
      : new Error("Unknown error", { cause: error })

  Bugsnag.notify(typedError)

  clog("Bugsnag notified: %o", typedError)
}
